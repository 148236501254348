
import { IObject } from '@/types/inerface'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'whereToGo',
  setup() {
    const router = useRouter()
    const store = useStore()
    let histroySearch = localStorage.searchList === undefined ? [] :JSON.parse(localStorage.searchList).reverse()
    let state = reactive({
      from: store.state.from,
      to: store.state.to
    })

    const handleToLocationPage = (flag: string) => {
      router.push({
        path: '/locationSearch',
        query: {
          which: flag
        }
      })
    }
    const handleToSearchPage = () => {
      if (state.from.staCode === '' || state.to.staCode === '') {
        return Toast.fail('请输入起点和终点')
      }
      histroySearch.push({
        from: state.from,
        to: state.to
      })
      localStorage.searchList = JSON.stringify(histroySearch)
      router.push({
        path: '/transPlan',
        query: {
          ...state.from,
          toName: state.to.name,
          toAddress: state.to.address,
          toStaCode: state.to.staCode
        }
      })
    }

    const handleSwitch = () => {
      const temp = state.from
      state.from = state.to
      state.to = temp
    }

    const handleHistory = (item: IObject) => {
      router.push({
        path: '/transPlan',
        query: {
          ...item.from,
          toName: item.to.name,
          toAddress: item.to.address,
          toStaCode: item.to.staCode
        }
      })
    }

    return {
      handleHistory,
      histroySearch,
      ...toRefs(state),
      handleToSearchPage,
      handleToLocationPage,
      handleSwitch,
    }
  },
})
