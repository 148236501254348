<template>
  <div class="content">
    <van-nav-bar
      title="换乘"
      fixed
      safe-area-inset-top
    />
    <div class="location">
      <div class="switch" @click="handleSwitch">
        <i class="iconfont icon-qiehuan"></i>
      </div>
      <div class="location-info">
        <p class="position" @click="handleToLocationPage('from')">
          <i class="iconfont icon-danseyuandian"></i>
          <span class="text">{{ from.name }}</span>
        </p>
        <p class="destination" @click="handleToLocationPage('to')">
          <i class="iconfont icon-danseyuandian"></i>
          <span class="text">{{ to.name }}</span>
        </p>
      </div>
      <div class="search" @click.stop.prevent="handleToSearchPage">
        <i class="iconfont icon-B"></i>
      </div>
    </div>
    <div class="tips">————————最近搜索————————</div>
    <div class="search-history">
      <div class="history" v-for="(item, index) in histroySearch" :key="index" @click="handleHistory(item)">
        <!-- <i class="exchange iconfont icon-qiehuan"></i> -->
        <div class="from-to">
          <p class="from"><i class="iconfont icon-danseyuandian"></i>{{item.from.name}}</p>
          <p class="to"><i class="iconfont icon-danseyuandian"></i>{{item.to.name}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="tips small">部分数据来源：高德实时公交</div> -->
</template>

<script lang="ts">
import { IObject } from '@/types/inerface'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'whereToGo',
  setup() {
    const router = useRouter()
    const store = useStore()
    let histroySearch = localStorage.searchList === undefined ? [] :JSON.parse(localStorage.searchList).reverse()
    let state = reactive({
      from: store.state.from,
      to: store.state.to
    })

    const handleToLocationPage = (flag: string) => {
      router.push({
        path: '/locationSearch',
        query: {
          which: flag
        }
      })
    }
    const handleToSearchPage = () => {
      if (state.from.staCode === '' || state.to.staCode === '') {
        return Toast.fail('请输入起点和终点')
      }
      histroySearch.push({
        from: state.from,
        to: state.to
      })
      localStorage.searchList = JSON.stringify(histroySearch)
      router.push({
        path: '/transPlan',
        query: {
          ...state.from,
          toName: state.to.name,
          toAddress: state.to.address,
          toStaCode: state.to.staCode
        }
      })
    }

    const handleSwitch = () => {
      const temp = state.from
      state.from = state.to
      state.to = temp
    }

    const handleHistory = (item: IObject) => {
      router.push({
        path: '/transPlan',
        query: {
          ...item.from,
          toName: item.to.name,
          toAddress: item.to.address,
          toStaCode: item.to.staCode
        }
      })
    }

    return {
      handleHistory,
      histroySearch,
      ...toRefs(state),
      handleToSearchPage,
      handleToLocationPage,
      handleSwitch,
    }
  },
})
</script>


<style lang="less" scoped>
.content {
  padding: 24px 16px 100px 16px;
  background-color: @base-background-color;
}
.location {
  margin-top: 100px;
  height: 160px;
  padding: 16px;
  background-color: #fff;
  display: flex;
  border-radius: 16px;
  .switch, .search {
    text-align: center;
    width: 60px;
    line-height: 160px;
    font-size: 36px;
    color: darkgray;
    i {
      font-size: 36px;
    }
  }
  .location-info {
    flex: 1;
    box-sizing: border-box;
    padding: 0 16px;
    font-size: 32px;
    p {
      margin: 0;
      line-height: 80px;
    }
    .position {
      border-bottom: 1px solid @base-background-color;
      .iconfont {
        color: #ff6900;
      }
    }
    .destination {
      .iconfont {
        color: #07c160;
      }
    }
    .text {
      .ellipse();
      margin-left: 16px;
      font-size: 28px;
      vertical-align: bottom;
    }
  }
}

.tips {
  margin-top: 32px;
  padding: 8px;
  background: @base-background-color;
  text-align: center;
  font-size: 24px;
  color: rgb(117, 117, 117);
  &.small {
    margin-top: 32px;
    font-size: 24px;
  }
}

.search-history {
  // background-color: #fff;
  .history {
    margin: 16px 0;
    display: flex;
    border-radius: 16px;
    background-color: #fff;
    padding: 16px;
    p {
      margin: 0;
      padding: 16px 0;
      font-size: 24px;
      color: #333;
    }
    .exchange {
      width: 80px;
      line-height: 130px;
      text-align: center;
      font-size: 36px;
      color: #ddd;
    }
    .from-to {
      flex: 1;
    }
  }
  .from {
    border-bottom: 1px solid @base-background-color;
    .iconfont {
      color: #ff6900;
    }
  }
  .to {
    .iconfont {
      color: #07c160;
    }
  }
}
</style>